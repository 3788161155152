<template>
    <div class="mt-10">
        <div
            v-editable="blok"
            class="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-wide lg:px-8"
        >
            <div class="flex items-center justify-between space-x-4">
                <h2
                    v-if="blok.title"
                    class="text-2xl font-extrabold tracking-wide"
                >
                    {{ blok.title }}
                </h2>
            </div>
            <ClientOnly>
                <Swiper
                    v-if="vehicles"
                    :modules="[SwiperFreeMode, Scrollbar]"
                    :free-mode="true"
                    :slides-per-view="1"
                    :space-between="25"
                    :scrollbar="true"
                    :breakpoints="{
                        640: {
                            slidesPerView: 2,
                        },

                        1024: {
                            slidesPerView: 4,
                        },
                    }"
                    class="mt-6"
                    @slide-change="slideChanged"
                >
                    <SwiperSlide
                        v-for="(vehicle, index) in vehicles"
                        :key="index"
                        class="group relative"
                    >
                        <GridVehicle
                            :vehicle="vehicle"
                            :show-history="showHistory"
                            :carfax-data="carfaxData"
                        />
                    </SwiperSlide>
                </Swiper>
            </ClientOnly>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { Swiper } from 'swiper'
import { Scrollbar } from 'swiper/modules'
import GridVehicle from '@/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicle.vue'
import { getSetting, queryToObject } from '@/utils/functions'
import type { Vehicle } from '~/utils/types/inventory'
import 'swiper/css/scrollbar'
import { trackMediaInteractionEvent } from '~/composables/asc/events/MediaInteraction'
import {
    ASCEventAction,
    ASCEventActionResult,
    ASCMediaType,
    ASCPageType,
} from '~/utils/types/asc/enums'

const inventoryStore = useInventoryStore()
const domainStore = useDomainStore()
const storyblokStore = useStoryblokStore()
const eventStore = useEventStore()

const props = defineProps({
    blok: {
        type: Object as PropType<Record<string, any>>,
        required: true,
    },
})

const vehicles = ref<Vehicle[]>([])
const carfaxData = ref<any[]>([])

const showHistory = computed(() => !(props.blok.hide_history ?? false))

onMounted(() => {
    const query = queryToObject(props.blok.query) ?? {}

    setTimeout(() =>
        inventoryStore
            .fetchHomePageInventory({
                ...query,
                ...{ limit: 4 },
            })
            .then((res) => {
                vehicles.value = res.data.value?.data ?? []

                if (showHistory.value && vehicles.value.length > 0) {
                    const carfaxIsEnabled = getSetting(
                        domainStore.autoZnetworkSettings,
                        'vendor.carfax.enabled',
                    )

                    if (carfaxIsEnabled && storyblokStore.srpHistoryEnabled) {
                        inventoryStore
                            .getCarfaxForVehiclesWithPromise(
                                vehicles.value.map(
                                    (vehicle: Vehicle) => vehicle.id,
                                ),
                            )
                            .then((data: any) => {
                                carfaxData.value = data.data.value?.data ?? []
                            })
                    }
                }
            }),
    )
})

function slideChanged(swiperEvent: Swiper) {
    trackMediaInteractionEvent({
        pageType: eventStore.ascPageType ?? ASCPageType.UNKNOWN,
        data: {
            event_action:
                swiperEvent.swipeDirection === 'next'
                    ? ASCEventAction.SWIPE_LEFT
                    : ASCEventAction.SWIPE_RIGHT,
            event_action_result: ASCEventActionResult.NONE,
            media_type: ASCMediaType.SLIDER,
        },
    })
}
</script>
